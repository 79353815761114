/** @jsxImportSource theme-ui */
import { jsx, Flex } from "theme-ui";
import { SupportCard, SupportCardProps } from "./SupportCard";

export interface SupportCardSectionProps {
  intro?: any;
  supportCardCollection: SupportCardProps[];
  bgColor?: string;
}

const SupportCardSection = (props: SupportCardSectionProps) => {
  const { intro, supportCardCollection, bgColor } = props;
  return (
    <section
      sx={{
        background: bgColor || "white",
        maxWidth: "1080px",
        mx: "auto",
      }}
    >
      <p
        sx={{
          variant: "text.basic",
          color: "secondary",
          textAlign: "center",
          mt: ["45px", null, "60px"],
          mb: "60px",
          px: "20px",
        }}
      >
        {intro || "Intro Line Here..."}
      </p>
      <Flex
        sx={{
          px: ["20px", null, null, 0],
          alignItems: ["center", null, "flex-start"],
          flexDirection: ["column", null, "row"],
          flexWrap: "wrap",
          justifyContent: "space-around",
          bg: props.bgColor || "white",
          rowGap: "30px",
          columnGap: "30px",
          mx: "auto",
          mb: ["75px", null, null, "120px"],
        }}
      >
        {supportCardCollection.map((sCard, i) => (
          <SupportCard key={i} {...sCard} />
        ))}
      </Flex>
    </section>
  );
};

export default SupportCardSection;
