/** @jsxImportSource theme-ui */
import React from "react";
import { SEO } from "../components/SEO";
import { DefaultLayout as Layout } from "../Layout/Default";

import { GenericPage } from "../data/ContentfulGenericPage";
import BasicHero from "../components/Hero/BasicHero";
import SupportCardSection from "../components/Cards/SupportCardSection";

const ContactUs = () => {
  const contactUsData = GenericPage.find(
    (page: any) => page.url == "/contact/"
  );
  console.log(contactUsData);
  return (
    <Layout
      showMasterDisclaimer={contactUsData?.showMasterDisclaimer}
      showCustomDisclaimers={contactUsData?.showCustomDisclaimers}
      disclaimers={contactUsData?.disclaimers}
    >
      <SEO
        title={contactUsData?.seo.title || undefined}
        description={contactUsData?.seo.description || undefined}
      />
      <BasicHero
        basicHero={contactUsData?.content?.find(
          (content: any) => content.name == "Contact Us Hero"
        )}
      />
      <SupportCardSection
        intro={
          contactUsData?.content?.find(
            (content: any) =>
              content.name == "Contact Us Support Card Collection"
          ).text.text
        }
        supportCardCollection={
          contactUsData?.content?.find(
            (content: any) =>
              content.name == "Contact Us Support Card Collection"
          ).supportCard
        }
      />
    </Layout>
  );
};

export default ContactUs;
