/** @jsxImportSource theme-ui */
import { jsx, Flex } from "theme-ui";
import { MDXRenderer } from "gatsby-plugin-mdx";

export interface SupportCardProps {
  title: any;
  titleColor?: string;
  titleBg?: string;
  copy: any;
  cardColor?: string;
  cardBg?: string;
}

export const SupportCard = (props: SupportCardProps): JSX.Element => {
  const { title, titleColor, titleBg, copy, cardBg, cardColor } = props;
  return (
    <Flex
      sx={{
        justifyContent: "center",
        minHeight: ["none", null, "372px"],
      }}
    >
      <Flex
        sx={{
          backgroundColor: titleBg || "background",
          width: "275px",
          height: "auto",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          minHeight: "118px",
          maxWidth: "245px",
          mx: "auto",
          variant: "text.h3",
          color: titleColor || "secondary",
          px: "15px",
          position: "absolute",
        }}
      >
        <h2>{title || "title required"}</h2>
      </Flex>
      <div
        sx={{
          borderTopWidth: "60px",
          borderColor: "white",
          bg: cardBg || "primary",
          pt: "70px",
          pb: "45px",
          px: "30px",
          width: "335px",
          p: {
            color: cardColor || "#fff",
            mb: "20px",
          },
          a: {
            variant: "text.link",
            color: "brand.tealLight",
            fontWeight: "bold",
          },
        }}
      >
        <MDXRenderer>
          {copy?.childMdx.body || "Card content required..."}
        </MDXRenderer>
      </div>
    </Flex>
  );
};

export default SupportCard;
