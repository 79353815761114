/** @jsxImportSource theme-ui */
import { jsx, Flex } from "theme-ui";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { Image } from "../utils";

export const BasicHero = (props: any) => {
  const { basicHero } = props;
  const content = (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: ["20px", null, 0],
        pt: ["37px", null, "75px"],
        pb: ["55px", null, "85px"],
      }}
    >
      <h1
        sx={{
          maxWidth: "536px",
          mx: "auto",
          variant: "text.h1",
          color: basicHero.titleColor || "white",
        }}
      >
        {basicHero.title || "title required"}
      </h1>
      <div sx={{ "& p": { color: basicHero.titleColor || "white" } }}>
        {basicHero.copy && (
          <MDXRenderer>{basicHero.copy.childMdx.body}</MDXRenderer>
        )}
      </div>
    </div>
  );

  return (
    <div>
      {basicHero.hasBackgroundImage ? (
        <Image
          image={basicHero.bgImage}
          background={true}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background:
              basicHero?.backgroundColor ||
              "transparent linear-gradient(180deg, #00A99D 0%, #1C174B 100%) 0% 0% no-repeat padding-box",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {content}
        </Image>
      ) : (
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background:
              basicHero?.backgroundColor ||
              "transparent linear-gradient(180deg, #00A99D 0%, #1C174B 100%) 0% 0% no-repeat padding-box",
          }}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default BasicHero;
